import {
  ON_USER_SIGN_IN_SUCCESS,
  ON_USER_SIGN_IN_FAIL,
  ON_USER_SIGN_OUT,
  RESET_ALL_REDUCERS,
  ON_USER_SIGN_IN_REQUEST,
  ON_CLOSE_MESSAGE,
  ON_CREATE_UPDATE_USER_REQUEST,
  ON_UPDATE_USER_SUCCESS,
  ON_CREATE_UPDATE_USER_ERROR,
  CLEAR_USER_STATE,
  CLEAR_REQUEST,
  ON_GET_USERS_LIST_SUCCESS,
  ON_GET_USERS_LIST_FAIL,
  ON_DELETE_USER_SUCCESS,
  ON_DELETE_USER_FAIL,
  ON_CREATE_USER_SUCCESS,
  ON_CREATE_USER_ERROR,
  EDIT_USER_FORM,
  CLEAN_USER_FORM,
  ON_CREATE_USER_REQUEST,
  ON_RESET_PASSWORD_SUCCESS,
  ON_RESET_PASSWORD_FAIL,
  ON_REMOVE_BOUSER_REQUEST,
  ON_REMOVE_BOUSER_SUCCESS,
  ON_REMOVE_BOUSER_ERROR,
  ON_ADD_BOUSER_REQUEST,
  ON_ADD_BOUSER_SUCCESS,
  ON_ADD_BOUSER_ERROR,
} from "../actions/types"

const emptyUser = {
  firstName: "",
  lastName: "",
  mobilePhone: "",
}

const INIT_STATE = {
  userData: null,
  authenticated: false,
  errTitle: null,
  errText: null,
  isLoading: false,
  token: null,
  showMessage: false,
  loadingCreateUpdateRequest: false,
  createUpdateRequestError: null,
  createUpdateRequsetSuccess: false,
  userDeletedSuccess: false,
  userDeleteFail: false,
  usersListArr: [],
  user: emptyUser,
  isUserCreatedSuccess: false,
  isUserCreatedFail: false,
  isUserExist: false,
  isPasswordResetSucces: false,
  isPasswordResetFail: false,
  isNeedReloadUsers: false,
}

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_USER_SIGN_IN_REQUEST:
      return {
        ...INIT_STATE,
        isLoading: true,
      }
    case ON_USER_SIGN_IN_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        authenticated: true,
        isLoading: false,
        showMessage: false,
      }
    case ON_USER_SIGN_IN_FAIL:
      return {
        ...state,
        userData: null,
        authenticated: false,
        errTitle: action.payload.errTitle,
        errText: action.payload.errText,
        isLoading: false,
        showMessage: true,
      }
    case ON_CLOSE_MESSAGE:
      return {
        ...state,
        showMessage: false,
      }
    case ON_USER_SIGN_OUT:
      return {
        ...INIT_STATE,
      }

    case ON_CREATE_UPDATE_USER_REQUEST:
      return {
        ...state,
        loadingCreateUpdateRequest: true,
        createUpdateRequestError: null,
        createUpdateRequsetSuccess: false,
      }
    case ON_UPDATE_USER_SUCCESS:
      return {
        ...state,
        loadingCreateUpdateRequest: false,
        createUpdateRequsetSuccess: true,
      }
    case ON_CREATE_UPDATE_USER_ERROR:
      return {
        ...state,
        loadingCreateUpdateRequest: false,
        createUpdateRequestError: true,
      }
    case RESET_ALL_REDUCERS:
    case CLEAR_USER_STATE:
      return {
        ...INIT_STATE,
      }
    case CLEAR_REQUEST:
      return {
        ...state,
        loadingCreateUpdateRequest: false,
        createUpdateRequestError: null,
        createUpdateRequsetSuccess: false,
        userDeletedSuccess: false,
        isUserCreatedSuccess: false,
        isUserCreatedFail: false,
        userDeleteFail: false,
        isPasswordResetSucces: false,
        isPasswordResetFail: false,
      }
    case ON_GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersListArr: action.payload,
        isLoading: false,
        isError: false,
        isNeedReloadUsers: false,
      }
    case ON_GET_USERS_LIST_FAIL:
      return {
        ...state,
        usersListArr: [],
        isLoading: false,
        isError: false,
      }
    case ON_DELETE_USER_SUCCESS:
      return {
        ...state,
        userDeletedSuccess: true,
        isLoading: false,
        isError: false,
      }
    case ON_DELETE_USER_FAIL:
      return {
        ...state,
        userDeletedSuccess: false,
        userDeleteFail: true,
        isLoading: false,
        isError: false,
      }

    case ON_CREATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case ON_CREATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isUserCreatedSuccess: true,
        isLoading: false,
      }
    case ON_CREATE_USER_ERROR:
      return {
        ...state,
        isUserCreatedFail: true,
        isUserExist: action.payload == "409" ? true : false,
        isLoading: false,
      }
    case EDIT_USER_FORM:
      return {
        ...state,
        user: action.payload,
      }
    case CLEAN_USER_FORM:
      return {
        ...state,
        user: emptyUser,
      }
    case ON_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordResetSucces: true,
        isPasswordResetFail: false,
      }
    case ON_RESET_PASSWORD_FAIL:
      return {
        ...state,
        isPasswordResetSucces: false,
        isPasswordResetFail: true,
      }
    case ON_REMOVE_BOUSER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case ON_REMOVE_BOUSER_SUCCESS:
      return {
        ...state,
        isNeedReloadUsers: true,
        isLoading: false,
        isError: false,
      }
    case ON_REMOVE_BOUSER_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case ON_ADD_BOUSER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case ON_ADD_BOUSER_SUCCESS:
      return {
        ...state,
        isNeedReloadUsers: true,
        isLoading: false,
        isError: false,
      }
    case ON_ADD_BOUSER_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    default:
      return state
  }
}

export default UserReducer
