import React, { Component } from "react"
import { connect } from "react-redux"
import Title from "../../common/Title"
import InputField from "../../common/InputField"
import { Button } from "../../common/Button"
import Loader from "../../common/Loader"
import { isValidField } from "../../../utils/FieldsValidation"
import Message from "../../common/Message"
import Select from "react-select"
import {
  onStartLoading,
  onCreateOrder,
  closeMessage,
  onGetStations,
  fetchAllStationData,
  onFetchExistOrders,
  getDeliveryDetailsFromIsraelPost,
  clearOrderUpdate,
  onCreateOrdersFromFile,
  clearCreateOrdersFromFile,
} from "../../../actions"

import "./style.scss"

class CreateNewOrderScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      packageNumber: "",
      mobilePhone: "",
      firstName: "",
      lastName: "",
      externalOrderNumber: "",
      showLoading: false,
      stationsList: [],
      stationNumber: "",
      currentStationName: "",
      stationsFiltered: [],
      isShowCreateOrdersFail: false,
      isShowCreateOrdersSuccess: false,
      errorText: "",

      validField: {
        stationNumber: {
          type: "stationNumber",
          isRequired: true,
          isChecked: false,
          compareMinValue: 1,
          compareMaxValue: 10,
          isValid: false,
        },
        packageNumber: {
          type: "packageNumber",
          isRequired: true,
          isChecked: false,
          compareMinValue: 1,
          compareMaxValue: 30,
          isValid: false,
        },
        mobilePhone: {
          type: "mobile",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        firstName: {
          type: "name",
          isRequired: true,
          isChecked: false,
          compareMinValue: 2,
          compareMaxValue: 30,
          isValid: false,
        },
        lastName: {
          type: "name",
          isRequired: true,
          isChecked: false,
          compareMinValue: 2,
          compareMaxValue: 30,
          isValid: false,
        },
        externalOrderNumber: {
          type: "externalOrderNumber",
          isRequired: true,
          isChecked: false,
          compareMinValue: 1,
          compareMaxValue: 30,
          isValid: false,
        },
      },
    }
    this.state = this.INIT_STATE
  }
  componentDidMount() {
    const { authenticated, history, onGetStations, orderList } = this.props

    if (!authenticated) {
      history.push("/")
    }
    this.state = this.INIT_STATE
    onGetStations()
    if (orderList && orderList.length == 0) {
      this.props.onFetchExistOrders()
    }
    if (this.props.userData?.orderTypeGroup == "4") {
      this.setState({
        stationNumber: { value: 1000 },
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      stationsAll,
      stationsItemSelectList,
      israelPostDelivery,
      createOrdersFromFileSuccess,
      createOrdersFromFileFail,
    } = this.props
    const { authenticated, history } = prevProps
    if (!authenticated) {
      history.push("/")
    }
    if (
      israelPostDelivery &&
      prevProps.israelPostDelivery !== israelPostDelivery
    ) {
      this.populateUserFieldsByPackage()
    }
    if (prevProps.stationsAll !== stationsAll) {
      this.setState({
        stationsList: [...stationsAll],
        currentStationName: stationsAll[0]?.stationName,
      })
      if (stationsAll && stationsItemSelectList.length == 0) {
        this.props.fetchAllStationData(stationsAll)
      }
    }
    if (
      stationsItemSelectList &&
      stationsItemSelectList.length == 1 &&
      this.state.stationNumber == ""
    ) {
      this.setState({ stationNumber: stationsItemSelectList[0] })
    }
    if (
      stationsItemSelectList &&
      stationsItemSelectList.length > 0 &&
      prevProps.stationsItemSelectList.length != stationsItemSelectList.length
    ) {
      this.selectStationForDecathlon()
    }

    if (createOrdersFromFileSuccess) {
      const failedOrders = this.checkForFailedOrders()
      this.setState({ failedOrdersList: failedOrders })
      this.generateResponseMsg(failedOrders)
    }
    if (createOrdersFromFileFail) {
      this.props.clearCreateOrdersFromFile()
      this.setState({ isShowCreateOrdersFail: true })
    }
  }

  componentWillUnmount() {
    this.state = this.INIT_STATE
  }

  checkForFailedOrders() {
    const { createOrdersFromFileResponse } = this.props
    let failedOrdersList = []
    if (createOrdersFromFileResponse?.data.length > 0) {
      createOrdersFromFileResponse?.data.forEach((order) => {
        if (order.status === false) {
          failedOrdersList.push(order.packageNumber)
        }
      })
    }
    return failedOrdersList
  }

  generateResponseMsg(failedOrders) {
    let msg = ""
    this.props.clearCreateOrdersFromFile()
    if (failedOrders.length == 0) {
      this.setState({ isShowCreateOrdersSuccess: true })
      return
    }
    if (
      failedOrders.length == this.props.createOrdersFromFileResponse.data.length
    ) {
      this.setState({ isShowCreateOrdersFail: true })
      return
    }
    if (failedOrders.length > 0) {
      msg = "ההזמנות הבאות נכשלו: "
      failedOrders.forEach((order) => {
        msg += order + ", "
      })
      msg = msg.substring(0, msg.length - 2)
      this.setState({ errorText: msg, isShowCreateOrdersSuccess: true })
      return
    }
  }

  setValue(inputName, e) {
    switch (inputName) {
      case "packageNumber":
        this.setState({ packageNumber: e.target.value })
        break
      case "mobilePhone":
        this.setState({ mobilePhone: e.target.value })
        break
      case "firstName":
        this.setState({ firstName: e.target.value })
        break
      case "lastName":
        this.setState({ lastName: e.target.value })
        break
      case "externalOrderNumber":
        this.setState({ externalOrderNumber: e.target.value })
        break
      default:
        break
    }
  }

  selectStationForDecathlon() {
    const { selectedDecathlonStation, stationsItemSelectList } = this.props
    if (selectedDecathlonStation && stationsItemSelectList) {
      var result = stationsItemSelectList.find((obj) => {
        return obj.value === selectedDecathlonStation
      })
      if (result) {
        this.setState({ stationsFiltered: result, stationNumber: result })
        return
      }
    }
    this.setState({
      stationsFiltered: stationsItemSelectList,
    })
  }

  onInputFocus(inputName) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = false
    field.isValid = false
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  validateInputValue(inputName, value) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return

    field.isChecked = true
    field.isValid = isValidField(
      value,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  isValidForm(order) {
    const { validField } = this.state
    this.validateInputValue("stationNumber", order.stationNumber)
    this.validateInputValue("packageNumber", order.packageNumber)
    this.validateInputValue("mobilePhone", order.mobilePhone)
    this.validateInputValue("firstName", order.firstName)
    this.validateInputValue("lastName", order.lastName)

    const isValid =
      validField.packageNumber.isChecked &&
      validField.packageNumber.isValid &&
      validField.mobilePhone.isChecked &&
      validField.mobilePhone.isValid &&
      validField.firstName.isChecked &&
      validField.firstName.isValid &&
      validField.lastName.isChecked &&
      validField.lastName.isValid &&
      validField.stationNumber.isChecked &&
      validField.stationNumber.isValid

    return isValid
  }

  handleFileChange(e) {
    const file = e.target.files[0]
    this.props.onCreateOrdersFromFile(file)
  }

  onClickCreateOrder() {
    const {
      stationNumber,
      packageNumber,
      mobilePhone,
      firstName,
      lastName,
      externalOrderNumber,
    } = this.state

    if (
      !this.isValidForm({
        stationNumber,
        packageNumber,
        mobilePhone,
        firstName,
        lastName,
        externalOrderNumber,
      })
    ) {
      return
    }

    this.props.clearOrderUpdate()
    this.props.onCreateOrder(
      stationNumber.value,
      packageNumber,
      mobilePhone,
      firstName,
      lastName,
      externalOrderNumber
    )
  }

  onMessageCkickOk(e) {
    const { isError } = this.props
    if (isError) {
      this.props.closeMessage()
      return
    }

    this.props.closeMessage()
    this.setState({
      packageNumber: "",
      mobilePhone: "",
      firstName: "",
      lastName: "",
      externalOrderNumber: "",
      validField: {
        stationNumber: {
          type: "stationNumber",
          isRequired: true,
          isChecked: false,
          compareMinValue: 1,
          compareMaxValue: 10,
          isValid: false,
        },
        packageNumber: {
          type: "packageNumber",
          isRequired: true,
          isChecked: false,
          compareMinValue: 1,
          compareMaxValue: 30,
          isValid: false,
        },
        mobilePhone: {
          type: "mobile",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        firstName: {
          type: "name",
          isRequired: true,
          isChecked: false,
          compareMinValue: 2,
          compareMaxValue: 30,
          isValid: false,
        },
        lastName: {
          type: "name",
          isRequired: true,
          isChecked: false,
          compareMinValue: 2,
          compareMaxValue: 30,
          isValid: false,
        },
        externalOrderNumber: {
          type: "externalOrderNumber",
          isRequired: true,
          isChecked: false,
          compareMinValue: 1,
          compareMaxValue: 30,
          isValid: false,
        },
      },
    })
  }

  findUserByPobilePhone(orderList, mobilePhone) {
    return orderList.find((order) => {
      if (order.mobilePhone === mobilePhone) {
        return order
      }
    })
  }

  populateUserFieldsByMobile(e) {
    const { orderList, stationsItemSelectList } = this.props
    const mobilePhone = e.target.value
    this.setValue("mobilePhone", e)

    if (mobilePhone.length == 10) {
      if (orderList && orderList.length > 0) {
        const order = this.findUserByPobilePhone(orderList, mobilePhone)
        if (order) {
          this.setState({
            firstName: order.firstName,
            lastName: order.lastName,
            stationNumber: this.setDefaultStation(order.stationNumber),
          })
        }
      }
    }
  }

  populateUserFieldsByPackage() {
    const { fName, lName, phone, stationNumber } = this.props.israelPostDelivery

    this.setState({
      firstName: fName,
      lastName: lName,
      mobilePhone: phone,
      stationNumber: this.setDefaultStation(stationNumber),
    })
  }

  setDefaultStation(stationNumber) {
    const { stationsFiltered } = this.state
    if (stationsFiltered.length == 1) {
      return stationsFiltered[0]
    }
    const station = stationsFiltered.find(
      (option) => option.value === stationNumber
    )
    return station ? station : this.state.stationNumber
  }

  onChangePackageNumberHandle(e) {
    this.setValue("packageNumber", e)
  }

  clearCreateOrdersFromFile() {
    this.props.clearCreateOrdersFromFile()
    this.setState({
      isShowCreateOrdersFail: false,
      isShowCreateOrdersSuccess: false,
      errorText: "",
    })
  }

  render() {
    const {
      validField,
      stationNumber,
      packageNumber,
      mobilePhone,
      firstName,
      lastName,
      externalOrderNumber,
      stationsFiltered,
      isShowCreateOrdersSuccess,
      isShowCreateOrdersFail,
      errorText,
    } = this.state

    const showSubmitButton = true
    const { showMessage, isError, isLoadingNewOrder } = this.props

    if (isLoadingNewOrder) {
      return (
        <div>
          <Title text="יצירת הזמנה חדשה" />
          <Loader show={true} />
        </div>
      )
    }

    return (
      <div className="create-order">
        <Title text="יצירת הזמנה חדשה" />
        <InputField
          isFocused={true}
          show={true}
          value={packageNumber}
          placeholder="מספר חבילה"
          mandatory={true}
          showValidation={validField.packageNumber.isChecked}
          showValidationOk={validField.packageNumber.isValid}
          showValidationError={!validField.packageNumber.isValid}
          validationErrorMessage="מספר חבילה לא תקין"
          onFocus={(e) => this.onInputFocus("packageNumber")}
          onBlur={(e) =>
            this.validateInputValue("packageNumber", e.target.value)
          }
          onChange={(e) => this.onChangePackageNumberHandle(e)}
          maxLength={30}
        />
        <InputField
          show={true}
          value={mobilePhone}
          placeholder="מספר טלפון"
          mandatory={true}
          showValidation={validField.mobilePhone.isChecked}
          showValidationOk={validField.mobilePhone.isValid}
          showValidationError={!validField.mobilePhone.isValid}
          validationErrorMessage="מספר טלפון לא תקין"
          onFocus={(e) => this.onInputFocus("mobilePhone")}
          onBlur={(e) => this.validateInputValue("mobilePhone", e.target.value)}
          onChange={(e) => this.populateUserFieldsByMobile(e)}
          maxLength={10}
        />
        <InputField
          show={true}
          value={firstName}
          iconStyle=""
          placeholder="שם פרטי"
          mandatory={true}
          showValidation={validField.firstName.isChecked}
          showValidationOk={validField.firstName.isValid}
          showValidationError={!validField.firstName.isValid}
          validationErrorMessage="שם פרטי לא תקין"
          onFocus={(e) => this.onInputFocus("firstName")}
          onBlur={(e) => this.validateInputValue("firstName", e.target.value)}
          onChange={(e) => this.setValue("firstName", e)}
          maxLength={30}
        />
        <InputField
          show={true}
          value={lastName}
          iconStyle=""
          placeholder="שם משפחה"
          mandatory={true}
          showValidation={validField.lastName.isChecked}
          showValidationOk={validField.lastName.isValid}
          showValidationError={!validField.lastName.isValid}
          validationErrorMessage="שם משפחה לא תקין"
          onFocus={(e) => this.onInputFocus("lastName")}
          onBlur={(e) => this.validateInputValue("lastName", e.target.value)}
          onChange={(e) => this.setValue("lastName", e)}
          maxLength={30}
        />
        {this.props.userData?.role == "DHL" ? (
          <InputField
            show={true}
            value={externalOrderNumber}
            iconStyle=""
            placeholder="pieceId"
            mandatory={true}
            showValidation={validField.externalOrderNumber.isChecked}
            showValidationOk={validField.externalOrderNumber.isValid}
            showValidationError={!validField.externalOrderNumber.isValid}
            validationErrorMessage="pieceId לא תקין"
            onFocus={(e) => this.onInputFocus("externalOrderNumber")}
            onBlur={(e) =>
              this.validateInputValue("externalOrderNumber", e.target.value)
            }
            onChange={(e) => this.setValue("externalOrderNumber", e)}
            maxLength={30}
          />
        ) : null}

        {this.props.userData?.orderTypeGroup != "4" ? (
          <div>
            <Select
              value={stationNumber}
              className="station-selector"
              placeholder="עמדה"
              options={
                Array.isArray(stationsFiltered)
                  ? stationsFiltered
                  : [stationsFiltered]
              }
              onChange={(itm) => this.setState({ stationNumber: itm })}
            />
            {validField.stationNumber.isChecked &&
            !validField.stationNumber.isValid ? (
              <div className="station-number-error">
                <span className="fa fa-times-circle"></span>
                <span>אנא בחר עמדה</span>
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="buttons-wrapper">
          <Button onClick={() => this.onClickCreateOrder()}>צור הזמנה</Button>
          {this.props.userData?.orderTypeGroup == "2" ? (
            <div>
              <label
                className="fileInputButton"
                onChange={(e) => this.handleFileChange(e)}
                htmlFor="ordersFile"
              >
                <input name="" type="file" id="ordersFile" hidden />
                טען קובץ
              </label>
              {isLoadingNewOrder ? "LoadingIndicator..." : null}
            </div>
          ) : null}
          <Button extraClass="back" onClick={() => this.props.history.goBack()}>
            חזור
          </Button>
        </div>

        <Message
          show={showMessage}
          isError={isError}
          successText="ההזמנה הוספה בהצלחה"
          errorText="הוספת הזמנה נכשלה"
          onClick={(e) => this.onMessageCkickOk(e)}
        />
        <Message
          show={isShowCreateOrdersSuccess}
          isError={isError}
          successText={
            <div>
              הזמנות נוספו בהצלחה{" "}
              {errorText ? (
                <div className="failed-orders">{errorText}</div>
              ) : (
                ""
              )}
            </div>
          }
          errorText=""
          onClick={() => this.clearCreateOrdersFromFile()}
        />
        <Message
          show={isShowCreateOrdersFail}
          isError={true}
          successText=""
          errorText=" הוספת הזמנה נכשלה"
          onClick={() => this.clearCreateOrdersFromFile()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ users, orders, station, israelPost }) => {
  const { authenticated, userData } = users
  const {
    showMessage,
    isError,
    isLoadingNewOrder,
    orderList,
    createOrdersFromFileResponse,
    createOrdersFromFileSuccess,
    createOrdersFromFileFail,
  } = orders
  const { stationsAll, stationsItemSelectList, selectedDecathlonStation } =
    station
  const { israelPostDelivery } = israelPost
  return {
    authenticated,
    showMessage,
    isError,
    isLoadingNewOrder,
    stationsAll,
    userData,
    orderList,
    stationsItemSelectList,
    israelPostDelivery,
    selectedDecathlonStation,
    createOrdersFromFileResponse,
    createOrdersFromFileSuccess,
    createOrdersFromFileFail,
  }
}

export default connect(mapStateToProps, {
  onStartLoading,
  onCreateOrder,
  closeMessage,
  onGetStations,
  fetchAllStationData,
  onFetchExistOrders,
  getDeliveryDetailsFromIsraelPost,
  clearOrderUpdate,
  onCreateOrdersFromFile,
  clearCreateOrdersFromFile,
})(CreateNewOrderScreen)
